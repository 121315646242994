.responsive-video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* for 16:9 aspect ratio */
  }
  
  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  